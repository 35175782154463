/* This util file acts as a master IntersectionObserver for all the BaseDynamicLoader instances.
It is responsible for executing the DOM loading/unloading callback methods of these instances.

Using a shared observer allows us to avoid creating multiple IntersectionObserver instances (saving memory), 
while maintaining the reusability of the BaseDynamicLoader component */

let observer = null;
let subscribers = new Map();

function createObserver(options) {
  if (observer) return observer;

  observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      const { callback, context } = subscribers.get(entry.target);
      if (callback) {
        callback.call(context, entry);
      }
    });
  }, options);
}

export function observe(element, callback, context, options) {
  const observer = createObserver(options);
  subscribers.set(element, { callback, context });
  observer.observe(element);
}

export function unobserve(element) {
  if (!observer) return;
  observer.unobserve(element);
  subscribers.delete(element);
}